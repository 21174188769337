<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
        <h4 class="title">
          {{ $t(`groups.${oper}`) }}
          <small></small>
        </h4>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('name_en') && touched.name_en},
                  {'md-error': errors.has('name_en')}
                ]"
              >
                <label>{{ $t('stdCols.name_en') }}</label>
                <md-input
                  v-model="name_en"
                  type="name_en"
                  data-vv-name="name_en"
                  required
                  v-validate="modelValidations.name_en"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name_en')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name_en') && touched.name_en">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('name_ru') && touched.name_ru},
                  {'md-error': errors.has('name_ru')}
                ]"
              >
                <label>{{ $t('stdCols.name_ru') }}</label>
                <md-input
                  v-model="name_ru"
                  type="text"
                  data-vv-name="name_ru"
                  required
                  v-validate="modelValidations.name_ru"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name_ru')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name_ru') && touched.name_ru">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('name_kg') && touched.name_kg},
                  {'md-error': errors.has('name_kg')}
                ]"
              >
                <label>{{ $t('stdCols.name_kg') }}</label>
                <md-input
                  v-model="name_kg"
                  type="text"
                  data-vv-name="name_kg"
                  required
                  v-validate="modelValidations.name_kg"
                ></md-input>
                <slide-y-down-transition>
                  <md-icon class="error" v-show="errors.has('name_kg')">close</md-icon>
                </slide-y-down-transition>
                <slide-y-down-transition>
                  <md-icon class="success" v-show="!errors.has('name_kg') && touched.name_kg">done</md-icon>
                </slide-y-down-transition>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {
                    'md-valid':
                      !errors.has('access_level') && touched.access_level
                  },
                  {'md-error': errors.has('access_level')}
                ]"
              >
                <label>{{ $t('groups.access_level') }}</label>
                <md-select
                  v-model="access_level"
                  name="access_level"
                  id="access_level"
                  data-vv-name="access_level"
                  required
                  v-validate="modelValidations.access_level"
                >
                  <md-option
                    v-for="acc_level in access_level_list"
                    :key="acc_level.id"
                    :value="acc_level.id"
                  >{{ acc_level[`name_${locale}`] }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-50">
              <md-field
                :class="[
                  {'md-valid': !errors.has('region_id') && touched.region_id},
                  {'md-error': errors.has('region_id')}
                ]"
              >
                <label>{{ $t('groups.region') }}</label>
                <md-select
                  v-model="region_id"
                  name="region_id"
                  id="region_id"
                  data-vv-name="region_id"
                  required
                  v-validate="modelValidations.region_id"
                >
                  <md-option
                    v-for="region in region_list"
                    :key="region.id"
                    :value="region.id"
                  >{{ region[`name_${locale}`] }}</md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button
              class="md-success"
              native-type="submit"
              @click.native.prevent="validate"
            >{{ $t('buttons.save') }}</md-button>
            <md-button class="md-accent" @click.stop.prevent="onCancel">{{ $t('buttons.close') }}</md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
import {mapState} from 'vuex'
import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'edit-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  components: {
    SlideYDownTransition
  },
  data() {
    return {
      gid: null,
      name_en: null,
      name_ru: null,
      name_kg: null,
      access_level: null,
      access_level_list: [],
      region_id: null,
      region_list: [],
      touched: {
        name_en: false,
        name_ru: false,
        name_kg: false,
        access_level: false,
        region_id: false
      },
      modelValidations: {
        name_en: {
          required: true,
          min: 3
        },
        name_ru: {
          required: true,
          min: 3
        },
        name_kg: {
          required: true,
          min: 3
        },
        access_level: {
          required: true,
          numeric: true
        },
        region_id: {
          required: true,
          numeric: true
        }
      }
    }
  },
  created() {
    const {gid = null} = this.$route.params
    this.gid = gid

    this.$store
      .dispatch('LOAD_REGION_LIST')
      .then((res) => (this.region_list = res))
    this.$store
      .dispatch('LOAD_ACC_LEVEL_LIST')
      .then((res) => (this.access_level_list = res))

    if (this.oper === 'upd' && gid) {
      this.$store.dispatch('LOAD_GROUP_BY_ID', gid).then((res) => {
        const group = Array.isArray(res) ? res[0] : res
        this.name_en = group.name_en
        this.name_ru = group.name_ru
        this.name_kg = group.name_kg
        this.access_level = group.access_level
        this.region_id = group.region_id
      })
    }
  },
  methods: {
    onCancel() {
      this.$router.push('/groups')
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      this.$validator.validateAll().then((isValid) => {
        if (isValid) {
          const group = {
            name_en: this.name_en,
            name_ru: this.name_ru,
            name_kg: this.name_kg,
            access_level: +this.access_level,
            region_id: +this.region_id
          }

          const alert = {
            type: 'success',
            text: this.$t(`groups.group_was_${this.oper}ed`, {
              name: this.name_en
            }),
            footer: ''
          }

          const reqData = this.oper === 'add' ? group : {id: this.gid, group}
          this.$store
            .dispatch(`${this.oper.toUpperCase()}_GROUP`, reqData)
            .then(
              () => {
                //Swal.fire('Hey user!', 'I don't like you.', 'warning')
                Swal.fire(alert).then(() => {
                  this.touched.name_en = false
                  this.touched.name_ru = false
                  this.touched.name_kg = false
                  this.touched.access_level = false
                  this.touched.region_id = false
                  this.$nextTick(() => this.$validator.reset())
                })
              },
              (err) => {
                alert.type = 'error'
                alert.text = this.$t(`groups.group_was_not_${this.oper}ed`, {
                  name: this.name_ru
                })
                alert.footer = err
                Swal.fire(alert)
              }
            )
        }
      })
    }
  },
  watch: {
    name_en() {
      this.touched.name_en = true
    },
    name_ru() {
      this.touched.name_ru = true
    },
    name_kg() {
      this.touched.name_kg = true
    },
    access_level() {
      this.touched.access_level = true
    },
    region_id() {
      this.touched.region_id = true
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.i18nStore.active
    })
  }
}
</script>
<style>
.md-button + .md-button {
  margin-left: 10px;
}
</style>

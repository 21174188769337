var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon",class:_vm.getClass(_vm.headerColor)},[_c('div',{staticClass:"card-icon"},[_c('md-icon',[_vm._v("perm_identity")])],1),_c('h4',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(`groups.${_vm.oper}`))+" "),_c('small')])]),_c('md-card-content',[_c('div',{staticClass:"md-layout md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('name_en') && _vm.touched.name_en},
                {'md-error': _vm.errors.has('name_en')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.name_en')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.name_en),expression:"modelValidations.name_en"}],attrs:{"type":"name_en","data-vv-name":"name_en","required":""},model:{value:(_vm.name_en),callback:function ($$v) {_vm.name_en=$$v},expression:"name_en"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('name_en')),expression:"errors.has('name_en')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('name_en') && _vm.touched.name_en),expression:"!errors.has('name_en') && touched.name_en"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('name_ru') && _vm.touched.name_ru},
                {'md-error': _vm.errors.has('name_ru')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.name_ru')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.name_ru),expression:"modelValidations.name_ru"}],attrs:{"type":"text","data-vv-name":"name_ru","required":""},model:{value:(_vm.name_ru),callback:function ($$v) {_vm.name_ru=$$v},expression:"name_ru"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('name_ru')),expression:"errors.has('name_ru')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('name_ru') && _vm.touched.name_ru),expression:"!errors.has('name_ru') && touched.name_ru"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('name_kg') && _vm.touched.name_kg},
                {'md-error': _vm.errors.has('name_kg')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.name_kg')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.name_kg),expression:"modelValidations.name_kg"}],attrs:{"type":"text","data-vv-name":"name_kg","required":""},model:{value:(_vm.name_kg),callback:function ($$v) {_vm.name_kg=$$v},expression:"name_kg"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('name_kg')),expression:"errors.has('name_kg')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('name_kg') && _vm.touched.name_kg),expression:"!errors.has('name_kg') && touched.name_kg"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {
                  'md-valid':
                    !_vm.errors.has('access_level') && _vm.touched.access_level
                },
                {'md-error': _vm.errors.has('access_level')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('groups.access_level')))]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.access_level),expression:"modelValidations.access_level"}],attrs:{"name":"access_level","id":"access_level","data-vv-name":"access_level","required":""},model:{value:(_vm.access_level),callback:function ($$v) {_vm.access_level=$$v},expression:"access_level"}},_vm._l((_vm.access_level_list),function(acc_level){return _c('md-option',{key:acc_level.id,attrs:{"value":acc_level.id}},[_vm._v(_vm._s(acc_level[`name_${_vm.locale}`]))])}),1)],1)],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
                {'md-valid': !_vm.errors.has('region_id') && _vm.touched.region_id},
                {'md-error': _vm.errors.has('region_id')}
              ]},[_c('label',[_vm._v(_vm._s(_vm.$t('groups.region')))]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.region_id),expression:"modelValidations.region_id"}],attrs:{"name":"region_id","id":"region_id","data-vv-name":"region_id","required":""},model:{value:(_vm.region_id),callback:function ($$v) {_vm.region_id=$$v},expression:"region_id"}},_vm._l((_vm.region_list),function(region){return _c('md-option',{key:region.id,attrs:{"value":region.id}},[_vm._v(_vm._s(region[`name_${_vm.locale}`]))])}),1)],1)],1)]),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-success",attrs:{"native-type":"submit"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('buttons.save')))]),_c('md-button',{staticClass:"md-accent",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onCancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('buttons.close')))])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }